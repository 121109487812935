/**
    NAVIGATION
**/

.navbar {
    .navbar-brand {
        margin-right: 2rem;
    }
}

.navbar-nav {
    align-items: baseline;
    .button-wrapper {
        justify-content: flex-end;
        margin: 0.5rem 0;
    }
    .col {
        display: flex;
    }
    gap: 20px;
    justify-content: flex-end;
    width: 100%;
}

@media screen and (max-width: 991px) {
    .navbar {
        height: 4.5rem;
    }

    .navbar > .container {
        flex-direction: column;
        align-content:flex-start;
        align-items: flex-start;
        justify-content: space-between;
    }

    .navbar-toggler {
        position: absolute;
        right: 1rem;
        top: 1rem;
    }

    .navbar-collapse {
        background-color: var(--bs-gray-200);
        left: 0;
        padding: 20px 23px;
        position: absolute;
        top: 4.5rem;
        width: 100%;
        z-index: 999;
    }

    .navbar-nav {
        align-items: baseline;
        flex-direction: column;
        font-size: 130%;
        gap: 20px;
        justify-content: flex-end;
        width: 100%;

        .account-nav .button-wrapper {
            flex-direction: row;
            justify-content: flex-end;
            margin: 0.5rem 0;
        }
        .col {
            display: flex;
            flex-direction: column;
        }
    } 
}

@media screen and (max-width: 576px) {
    .navbar {
        .navbar-brand {
            font-size: 1rem;
        }
    }
}

