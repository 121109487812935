@font-face {
    font-family: "SourceSansPro-Regular";
    src: url("../fonts/SourceSansPro-Regular.ttf");
}

@font-face {
    font-family: "SourceSansPro-Bold";
    src: url("../fonts/SourceSansPro-Bold.ttf");
}

@font-face {
    font-family: "SourceSansPro-Light";
    src: url("../fonts/SourceSansPro-Light.ttf");
}

/* CUSTOM BREAKPOINTS */
$mobil-nav-breakpoint-width: 991px;

$font-family-light: "SourceSansPro-Light";
$font-family-sans-serif: "SourceSansPro-Regular";
$font-family-base: 1.2rem;
$table-caption-font-size: 1.8rem;
$font-size-small: 90%;
$base-padding: 20px;
$base-row-gap: 20px;
$base-col-gap: 20px; 
$base-margin: 20px 0;
$main-margin: 40px 0 100px;
/* min. 60px at the bottom for the footer */
$section-padding: 10px 0;
$border-radius: 2px;
$buttonMargins: 15px 0 20px;

// Color system
$poor-practice: #efa500
    /* instead of $warning */
;
$bs-body-color: #212529;

// scss-docs-start gray-color-variables
$white: #fff;
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #6c757d;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;
$black: #000;
// scss-docs-end gray-color-variables

// fusv-disable
// scss-docs-start gray-colors-map
$grays: (
    "100": $gray-100,
    "200": $gray-200,
    "300": $gray-300,
    "400": $gray-400,
    "500": $gray-500,
    "600": $gray-600,
    "700": $gray-700,
    "800": $gray-800,
    "900": $gray-900
);
// scss-docs-end gray-colors-map
// fusv-enable

// Custom Colors
$mint: #70c6b4;
$petrol: #106c90;
$blue: #0d6efd;
$indigo: #6610f2;
$purple: #6f42c1;
$pink: #d63384;
$red: #dc3545;
$orange: #fd7e14;
$yellow: #ffc107;
$green: #198754;
$teal: #20c997;
$cyan: #0dcaf0;
// Custom Colors


// scss-docs-start colors-map
$colors: (
    "mint": $mint,
    "petrol": $petrol,
    "blue": $blue,
    "indigo": $indigo,
    "purple": $purple,
    "pink": $pink,
    "red": $red,
    "orange": $orange,
    "yellow": $yellow,
    "green": $green,
    "teal": $teal,
    "cyan": $cyan,
    "white": $white,
    "gray": $gray-600,
    "gray-dark": $gray-800
);
// scss-docs-end colors-map


// scss-docs-start theme-color-variables
$primary: $mint;
$secondary: $petrol;
$success: $green;
$info: $cyan;
$warning: $yellow;
$danger: $red;
$light: $gray-100;
$dark: $gray-900;
$link-color: $secondary;
$table-caption-color: $bs-body-color;
// scss-docs-end theme-color-variables

// scss-docs-start theme-colors-map
$theme-colors: (
    "primary": $primary,
    "secondary": $secondary,
    "success": $success,
    "info": $info,
    "warning": $warning,
    "danger": $danger,
    "light": $light,
    "dark": $dark
);
// scss-docs-end theme-colors-map

// scss-docs-start theme-colors-rgb
// $theme-colors-rgb: map-loop($theme-colors, to-rgb, "$value");
// scss-docs-end theme-colors-rgb

$borderRadius: 3px;

body {
    --bs-body-font-family: 'SourceSansPro-Regular';
    --bs-body-font-size: $font-family-base;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
    font-family: "SourceSansPro-Light";
}

.main {
    margin: $main-margin;
    margin-bottom: 0;
    padding-bottom: 100px;
    width: 100%;
    @media (max-width: 768px) {
        min-height: calc(100vh - 94px - 4.5rem);
    }
    @media (max-width: 415px) {
        min-height: calc(100vh - 120px - 4.5rem);
    }
}

.container {
    @media (max-width: $mobil-nav-breakpoint-width) {
        max-width: 100% !important;
    }
}

.section {
    padding: $section-padding;
}

.sct-btn {
    &.light {
        background-color: unset;
        border: none;
        &:hover {
            color: #000;
            background-color: unset;
            border-color: #7eccbc;
        }
        &:focus {
            background-color: unset;
            box-shadow: 0 0 0.25rem rgba($primary, .5);
        }
    }
}

.button-wrapper {
    display: flex;
    gap: 1rem;
    margin: $buttonMargins;

    @media (max-width: 576px) {
        flex-direction: column;
    }
}

.module-title-section {
    min-height: 120px;
    display: flex;
    flex-direction: row;
    gap: 0 $base-col-gap;
    align-items: center;
}

.module-img-wrapper {
    align-content: center;
    border: 1px solid $primary;
    border-radius: $borderRadius;
    display: flex;
    height: 80px;
    justify-content: center;
    overflow: hidden;
    width: 80px;

    .module-img {
        height: auto;
        max-height: 78px;
        max-width: 78px;
        object-fit: contain;
        width: auto;
    }
}

.sct-tagline {
    font-size: 130%;
    font-weight: 400;
    margin: $base-margin;
}

.sct-modul-intro {
    font-weight: 500;
}

.sct-YT-video {
    margin: $base-margin;
}

/**
    is loading spinner
**/

.is-loading-spinner-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 999;
    width: 100%;
    background-color: #70c6b4d4;
    height: 100%;
}

/**
    ELEARNING
**/

@media (min-width: 800px) {
    .aligned-lg-42 {
        margin-top: 42px;
    }
}

/**
    MODULE OVERVIEW
**/

/**
    Module Thumbnail
**/

.module-thumbnail-wrapper {
    align-items: center;
    border: 2px solid $primary;
    border-radius: 2px;
    display: flex;
    flex-direction: column;
    height: 250px;
    justify-content: center;
    margin: 12px 0;
    overflow: hidden;
    padding: 0.5rem;
    position: relative;
}

.module-thumbnail-header {
    position: absolute;
    bottom: 0;
    color: $bs-body-color;
}

.module-thumbnail-body {
    display: flex;
    justify-content: center;
}

.module-thumbnail-body img {
    max-height: 150px;
    max-width: 230px;
    width: auto;
}

span.module-thumbnail-patch {
    font-size: 150%;
    position: absolute;
    right: 0.4rem;
    top: 0.1rem;
}

/**
    CARD
**/

.sct-bs-card-row.card {
    flex-direction: row;
    @media (max-width: 769px) {
        flex-direction: column;
    }
}


.sct-bs-card-img-top.card-img-top {
    height: 150px;
    width: auto;
    margin: 0 auto;
    padding: 1rem;
}

/**
    TAB
**/

.tab-content {
    padding: $base-padding;
}

/**
    TABLE
**/

@media (max-width: 576px) {
    .table-responsive table {
        border-spacing: 0 20px;
        border-collapse: separate;
        display: grid;

        thead {
            display: none;
        }

        tbody {
            td {
                background-color: var(--bs-table-striped-bg);
                float: left;
                width: 50% !important;
                height: 40px;
                border: 0;
                display: flex;
                padding: 0rem 0.5rem 1rem;
                align-items: center;

                &:nth-child(1) {
                    padding-bottom: 0;
                    width: 100% !important;
                }

                &:nth-child(2) {
                    padding-bottom: 1rem;
                    width: 100% !important;
                }
            
                div,
                button {
                    width: 100%;
                }
            }
        }
    }
}

.table-caption-lg-top {
    color: $bs-body-color;
    text-align: left;
    caption-side: top;
    font-size: 1.5rem;
    font-family: $font-family-light;
}

caption {
    caption-side: top;
    color: $bs-body-color;
    font-family: $font-family-light;
    font-size: $table-caption-font-size;
}

/**
    MODAL
**/

.modal-80w {
    min-width: 80%;
}

.modal-dialog {
    .modal-header {
        .btn-print {
            font-size: 1.2rem;
            margin: 0 1rem 0 auto;
        }
        .btn-close {
            margin-left: unset;
        }
    }
}

/**
    FORM
**/

.contact-form {
    input {
        margin-bottom: 15px; 
    }
    display: flex;
    flex-direction: column;
}

.sct-form-button-nav {
    column-gap: 7px;
    display: flex;
    margin: $buttonMargins;
}

small.required-field.form-text {
    color: $danger;
    margin-left: 0.7rem;
}

.invalid-field-value {
    color: #212529;
    background-color: #fff;
    border: 1px solid $danger;
    outline: 0;
    box-shadow: 0 0 0 0.25rem #ffb5b8;
}

/* Hide Input field arrows on Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Hide Input field arrows on Firefox */
input[type=number] {
    -moz-appearance: textfield;
    appearance: textfield;
}

.form-check {
    display: flex;
    flex-direction: row;
    gap: 15px;
    align-items: center;
}

.form-check-label {
    position: relative;
    top: 2px;
}

.form-img {
    max-width: 900px;
    margin: 30px 0 0;
    width: 100%;
}

/* CHECKBOX */

.checkbox-hint {
    font-size: $font-size-small;
    margin: -5px 0 20px;
}

.true {
    color: $green;
}

.poor {
    color: $poor-practice;
}

.false {
    color: $danger;
}

.highlighted {
    font-weight: bold;
}

.blured {
    color: $gray-600;
}

.feedback {
    color: $gray-700;
}

/* FORM VALIDATION */

select.form-validation-error,
select.form-validation-error:focus {
	border-color: $danger;
	box-shadow: 0px 0px 3px $danger;
}

.form-validation-error-message {
    color: $danger
}


/* list-items */
.list-item {
    margin: 0 0 30px;
    border: 1px solid var(--bs-mint);
    border-radius: $border-radius;
    padding: 20px;
    // .list-item-body {
    //     .true {
    //         color: $green;
    //     }

    //     .false {
    //         color: $danger;
    //     }

    //     .highlighted {
    //         font-weight: bold;
    //     }

    //     .blured {
    //         color: $gray-600;
    //     }
    //     .feedback {
    //         color: $gray-700;
    //     }
    // }
}

.no-list-style {
    list-style: none;
    padding-left: unset;
}

.list-item-title h4 {
    font-weight: 600;
}

.highlighted {
    font-size: 105%;
    margin-top: 20px;
    font-weight: 400;
}

/**
    AMPEL
**/

.ampel-wrapper {
    flex-direction: row;
    max-width: fit-content;
    display: flex;
    gap: 10px;
    align-items: center;
}

.ampel-licht {
    // border: 1px solid lightgray;
    border-radius: 3px;
    height: 20px;
    width: 30px;
    display: flex;
    opacity: 0.15;
}

.redlight {
    background-color: $danger;
}

.orangelight {
    background-color: $warning;
}

.greenlight {
    background-color: $success;
}

.on {
    opacity: 1;
    box-shadow: 1px 2px 4px grey;
}

.result-summary {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.summary-risk-wrapper .progress {
    height: 30px;
    font-size: 1em;
    max-width: 50%;
}

.summary-risk-title {
    margin-bottom: 1.2rem;
}

/* RESULT SUMMARY */

.summary-body-wrapper {
    background-color: #e9ecef;
    border-radius: $border-radius;
    padding: 1.25rem;
}

.summary-risk-bar {
    margin-bottom: 20px;
}

td .summary-risk-bar {
    margin-bottom: 0;
}

.summary-legend-wrapper {
    background-color: $gray-100;
    border-radius: $border-radius;
    padding: 0.7rem;
}

.summary-legend-title h4 {
    font-size: 1.3rem;
}

.legend-entry-title {
    margin: 0.5rem 0 0.5rem;
}

.legend-entry-title h5 {
    margin-bottom: 0;
    font-size: 1.2rem;
}

/* DETAILED RESULTS */

.feedback-title {
    font-weight: bold;
}


/* FOOTER */

.footer {
    background-color: $petrol;
    color: $primary;
    height: auto;
    line-height: 1.4;
    padding: $base-padding;

    a {
        color: $primary;
        text-decoration: none;

        &:hover {
            color: $primary;
            text-decoration: underline;
        }

    }

    .footer-content {
        display: flex;
        flex-direction: row;
    }

    @media (max-width: 991px) {
        text-align: center;

        .footer-content {
            flex-direction: column-reverse;
            gap: 10px;
        }
    }

    @media (min-width: 768px) {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        height: auto;
        z-index: 999;
    }

    @media (max-width: 767px) {
        position: relative;
        bottom: 0;
        left: 0;
        width: 100%;
        height: auto;
        max-height: 120px;
        z-index: 999;
    }
}

/* Import component styles */
@import "../components/navigation/navigation.scss";
@import "../components/modules/modules.scss";
@import "../components/assessement/assessment-overview.scss";
@import "../components/retakes/retakes-overview.scss";
@import "../components/logos/logos.scss";
@import "../components/video/video.scss";

/* Import bootstrap to set changes */
@import "../../node_modules/bootstrap/scss/bootstrap";