.assessment-overview-table td {
  vertical-align: middle;
}

.assessment-overview-table .button-col {
  width: 130px;
}

.assessment-overview-table td .button-col {
  text-align: right;
}

.module-col {
  width: 250px;
}