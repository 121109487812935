

.sct-video-pl {
  list-style: none;
  li {
    font-size: 120%;
  }
}

.sct-video-pls-item.active {
  color: $petrol;
}

.sct-auto-resizable-iframe {
  position: relative;
  padding-bottom: 56%;
  height: 0px;

  iframe {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
  }
}