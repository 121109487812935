.logo-wrapper.row {
  margin: 25px 0;
  gap: 35px 0;
  div {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-basis: unset;

    img.logo {
      max-height: 60px;
      max-width: 100%;
      width: auto;
    }
  }
}
