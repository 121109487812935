/* modules.scss */

.module-in-progress::before {
  background-color: #ffcf00;
  width: 200px;
  height: 35px;
  content: 'In Bearbeitung';
  text-decoration: none;
  color: #333;
  font-weight: 500;
  text-align: center;
  padding: 0.3em;
  transform: rotate(-45deg);
  position: absolute;
  left: -50px;
  top: 40px;
}