
.retakes-overview-table {
  max-width: 1272px;
  margin: 0 auto;
}

.retakes-overview-table td {
  vertical-align: middle;
}

.date-col {
  width: 150px;
}

.button-col {
  width: 100px;
}